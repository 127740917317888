$theme: #003a6f;
$theme2: #87ceeb;
$white: #fff;
$black: #000;
$xs: 767px;
$sm: 768px;
$md: 1024px;
$lg: 1200px;


header{
    background-color: $theme;
    nav{
        .logo {
            height: 54px;
        }
        li.nav-item {
            a {
                color: $theme2;
                text-transform: uppercase;
            }
        }
    }
}
 @media (min-width: $sm) {
    #homeSlider {
        .d-block{
            width: 100%;
        }
       .carousel-caption {
          bottom: 35%;
          h3{
              font-size: 38px;
          }
          p{
              font-size: 20px;
          }
        }
    }
 }
 .bg-theme{
     background-color: $theme;
 }
 .title{
     text-transform: uppercase;
 }
 .btn-theme{
     background-color: $theme !important;
     color: $white !important;
     border: 1px solid $theme;
     transition: all 0.3s ease-in-out;
     &:hover{
         background-color: $white !important;
         color: $theme !important;
         border: 1px solid $theme;
         letter-spacing: 1px;
     }
 }
 footer{
     background-color: #eee;
     .list-unstyled{
         a{
            transition: all 0.3s ease-in-out;
     &:hover{
         letter-spacing: 1px;
     }
         }
     }
     p{
         color: $black;
         margin-bottom: 0px;
         
     }
     li{
         color: $black;
         font-size: 14px;
         font-weight: 200;
         line-height: 22px;
     }
     h5{
         color: $theme;
     }
     a{
         text-decoration: none;
         color: $theme;
         font-size: 16px;
         font-weight: 300;
         &:hover{
             color: blue;
         }
     }
 }
 .img-circle{
     border-radius: 50%;
 }
 .services {
     .card{
        box-shadow: 1px 4px 8px #0000001f;
     }
     img{
         border-top-left-radius: 4px;
         border-top-right-radius: 4px;
     }
 }
.social{
    list-style-type: none;
    display: flex;
    margin-bottom: 5px;
    padding-left: 0px;
    li{
        a{
            text-decoration: none;
            color: $white;
            span{
                position: relative;
                top: 2px;
            }
            img{
                height: 28px;
                margin-right: 5px;
                margin-top: 5px;
                background-color: $white;
                border-radius: 50%;
                border: 1px solid $white;
            }
        }
    }
}
ul.social.ml-auto {
    float: right;
    li {
        a {
            margin-left: 10px;
        }
    }
}
@media (max-width: $sm) {
    ul.social.fl-auto {
        float: none;
    }
    ul.social{
        justify-content: center;
    }
}
.banners{
   position: relative;
   .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0004;
}
}

.portfolio {
    ul.nav {
        li.nav-item{
             a {
                color: $theme;
                background-color: #f9f9f9;
                margin-left: 5px;
                margin-right: 5px;
                border: 1px solid;
            }
            a.active {
                color: $white;
                border: 1px solid $theme;
                background-color: $theme;
            }
        }
    }
    .card {
        margin-bottom: 20px;
        .card-img-overlay {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            background-color: #0004;
            * {
                color: #ffff;
             }
            a{
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                color: $theme;
                background-color: $theme2;
                border: 1px solid $theme2;
            }
         }
    }
}
.clientlogo{
    .card{
        border: none;
        .card-body {
            border: 1px solid #ccc;
            margin: 0px 10px;
            border-radius: 4px;
            display: flex;
        }
    }

}
.banner {
    padding: 0px;
    .row{
        --bs-gutter-x: 0;
        background-color: $theme;
        max-width: 1920px;
        margin: auto;
        .bg-theme {
            padding-left: 30px;
            h1 {
                color: $white;
                line-height: 56px;
                span{
                    display: block;
                }
            }
            p{
                color: $white;
                font-weight: 300;
                font-size: 20px;
                margin-top: 15px;
            }
            .btn-theme2 {
                margin-top: 15px;
                background-color: $theme2;
                border-radius: 0px;
                font-size: 18px;
                font-weight: 600;
                padding: 10px 30px;
                border: 2px solid $theme2;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: $theme;
                    border: 2px solid $white;
                    color: $white;
                    letter-spacing: 1px;
                }
            }
        }
    }
   
}
.bg-theme2{
    background-color: $theme2;
}