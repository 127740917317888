header {
  background-color: #003a6f;
}

header nav .logo {
  height: 54px;
}

header nav li.nav-item a {
  color: #87ceeb;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  #homeSlider .d-block {
    width: 100%;
  }
  #homeSlider .carousel-caption {
    bottom: 35%;
  }
  #homeSlider .carousel-caption h3 {
    font-size: 38px;
  }
  #homeSlider .carousel-caption p {
    font-size: 20px;
  }
}

.bg-theme {
  background-color: #003a6f;
}

.title {
  text-transform: uppercase;
}

.btn-theme {
  background-color: #003a6f !important;
  color: #fff !important;
  border: 1px solid #003a6f;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-theme:hover {
  background-color: #fff !important;
  color: #003a6f !important;
  border: 1px solid #003a6f;
  letter-spacing: 1px;
}

footer {
  background-color: #eee;
}

footer .list-unstyled a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer .list-unstyled a:hover {
  letter-spacing: 1px;
}

footer p {
  color: #000;
  margin-bottom: 0px;
}

footer li {
  color: #000;
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
}

footer h5 {
  color: #003a6f;
}

footer a {
  text-decoration: none;
  color: #003a6f;
  font-size: 16px;
  font-weight: 300;
}

footer a:hover {
  color: blue;
}

.img-circle {
  border-radius: 50%;
}

.services .card {
  -webkit-box-shadow: 1px 4px 8px #0000001f;
          box-shadow: 1px 4px 8px #0000001f;
}

.services img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.social {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  padding-left: 0px;
}

.social li a {
  text-decoration: none;
  color: #fff;
}

.social li a span {
  position: relative;
  top: 2px;
}

.social li a img {
  height: 28px;
  margin-right: 5px;
  margin-top: 5px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
}

ul.social.ml-auto {
  float: right;
}

ul.social.ml-auto li a {
  margin-left: 10px;
}

@media (max-width: 768px) {
  ul.social.fl-auto {
    float: none;
  }
  ul.social {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.banners {
  position: relative;
}

.banners .content {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #0004;
}

.portfolio ul.nav li.nav-item a {
  color: #003a6f;
  background-color: #f9f9f9;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid;
}

.portfolio ul.nav li.nav-item a.active {
  color: #fff;
  border: 1px solid #003a6f;
  background-color: #003a6f;
}

.portfolio .card {
  margin-bottom: 20px;
}

.portfolio .card .card-img-overlay {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  background-color: #0004;
}

.portfolio .card .card-img-overlay * {
  color: #ffff;
}

.portfolio .card .card-img-overlay a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #003a6f;
  background-color: #87ceeb;
  border: 1px solid #87ceeb;
}

.clientlogo .card {
  border: none;
}

.clientlogo .card .card-body {
  border: 1px solid #ccc;
  margin: 0px 10px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner {
  padding: 0px;
}

.banner .row {
  --bs-gutter-x: 0;
  background-color: #003a6f;
  max-width: 1920px;
  margin: auto;
}

.banner .row .bg-theme {
  padding-left: 30px;
}

.banner .row .bg-theme h1 {
  color: #fff;
  line-height: 56px;
}

.banner .row .bg-theme h1 span {
  display: block;
}

.banner .row .bg-theme p {
  color: #fff;
  font-weight: 300;
  font-size: 20px;
  margin-top: 15px;
}

.banner .row .bg-theme .btn-theme2 {
  margin-top: 15px;
  background-color: #87ceeb;
  border-radius: 0px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
  border: 2px solid #87ceeb;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.banner .row .bg-theme .btn-theme2:hover {
  background-color: #003a6f;
  border: 2px solid #fff;
  color: #fff;
  letter-spacing: 1px;
}

.bg-theme2 {
  background-color: #87ceeb;
}
